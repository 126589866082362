import React from "react";
import { createPortal } from "react-dom";

import usePortal from "../hooks/usePortal";
import { isBrowser } from "../services/auth";

/**
 * @example
 * <Portal>
 *   <p>Thinking with portals</p>
 * </Portal>
 */

let Portal: React.FC<{ id: any }>;

if (typeof document !== "undefined") {
  Portal = ({ id, children }) => {
    const target = usePortal(id);
    return createPortal(children, target);
  };
} else {
  Portal = null;
}

export default Portal;
