import React, { ReactElement, ButtonHTMLAttributes } from "react";
import { Link } from "@reach/router";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  url?: string;
  action?: () => void;
  additionalContainerClasses?: string;
  icon?: ReactElement;
  additionalButtonClasses?: string;
}

const PrimaryButton: React.FC<Props> = ({
  title,
  url,
  additionalContainerClasses,
  icon,
  action,
  type,
  disabled,
  additionalButtonClasses,
}) => {
  const bClasses = `capitalize shadow-md inline-flex items-center px-6 py-2 sm:py-4 border border-transparent text-sm sm:text-lg leading-5 font-semibold rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700 transition duration-150 ease-in-out ${additionalButtonClasses}`;

  return (
    <span className={`rounded-md ${additionalContainerClasses}`}>
      {type === "submit" && (
        <button type={"submit"} className={bClasses} disabled={disabled}>
          {icon && (
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              {icon}
            </span>
          )}
          {title}
        </button>
      )}
      {url && (
        <Link to={url} className={bClasses}>
          {icon && <span className="">{icon}</span>}
          {title}
        </Link>
      )}
      {action && (
        <button
          type={type}
          className={bClasses}
          disabled={disabled}
          onClick={action}
        >
          {icon && (
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              {icon}
            </span>
          )}
          {title}
        </button>
      )}
    </span>
  );
};

export default PrimaryButton;
