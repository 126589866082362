import React from "react";

const PageContent: React.FC = ({ children }) => (
  <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="bg-white shadow overflow-hidden sm:rounded-md mt-8">
      {children}
    </div>
  </div>
);

export default PageContent;
