import React, { useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import tw from "twin.macro";
import css from "@emotion/core";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import Layout from "../../layouts/main";
import BankAccountForm from "../../components/forms/addBankAccount";
import CreditDebitCardForm from "../../components/forms/addCreditDebitCard";
import { stripePromise, translate } from "../../helpers";
import PageHeader from "../../layouts/pageHeader";
import PageContent from "../../layouts/pageContent";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import SecondaryButton from "../../components/buttons/secondary";

const AddPage: React.FC<RouteComponentProps> = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const language = useLanguagePreference();

  return (
    <Layout>
      <PageHeader
        title={translate("screen_title_add_payment_method", language)}
      >
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <SecondaryButton
            title={translate("button_back", language)}
            url={"/app/payment-methods"}
            icon={
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                />
              </svg>
            }
          />
        </div>
      </PageHeader>
      <PageContent>
        <div className="sm:hidden">
          <select
            aria-label="Selected tab"
            onChange={event => {
              const value = event.target.value;
              setSelectedTab(Number(value));
            }}
            className="form-select block w-full rounded-none border-0 border-b"
          >
            <option value={0}>
              {translate("label_bank_account", language)}
            </option>
            <option value={1}>{translate("label_card", language)}</option>
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="flex -mb-px">
              <button
                type="button"
                onClick={() => setSelectedTab(0)}
                css={[
                  tw`w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm leading-5 border-transparent focus:outline-none`,
                  selectedTab === 0
                    ? tw`border-teal-500 text-teal-600 focus:text-teal-600 focus:border-teal-500 hover:text-teal-600 hover:border-teal-500`
                    : tw`focus:text-gray-700 focus:border-gray-300 text-gray-500 hover:text-gray-700 hover:border-gray-300`,
                ]}
                aria-current={selectedTab === 0 ? "page" : "false"}
              >
                {translate("label_bank_account", language)}
              </button>
              <button
                type="button"
                onClick={() => setSelectedTab(1)}
                css={[
                  tw`w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm leading-5 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300`,
                  selectedTab === 1
                    ? tw`border-teal-500 text-teal-600 focus:text-teal-600 focus:border-teal-500 hover:text-teal-600 hover:border-teal-500`
                    : tw`focus:text-gray-700 focus:border-gray-300 text-gray-500 hover:text-gray-700 hover:border-gray-300`,
                ]}
                aria-current={selectedTab === 1 ? "page" : "false"}
              >
                {translate("label_card", language)}
              </button>
            </nav>
          </div>
        </div>
        {/* Bank Account Tab */}
        {selectedTab === 0 && <BankAccountForm />}
        {/* Debit/Credit Card Tab */}
        {selectedTab === 1 && (
          <>
            <Elements stripe={stripePromise}>
              <CreditDebitCardForm />
            </Elements>
          </>
        )}
      </PageContent>
    </Layout>
  );
};

export default AddPage;
