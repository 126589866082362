import React, { useCallback, useState, useEffect } from "react";
import { connectAutoComplete } from "react-instantsearch-dom";
import Portal from "./portal";
import BusinessCard from "./cards/business";
import { Business, useMyFavoritesQuery } from "../generated/graphql";
import Dropdown, { Item } from "./dropdown";
import { translate } from "../helpers";
import useLanguagePreference from "../hooks/useLanguagePreference";
import LoadingModal from "../components/modals/loading";

const SearchBox: React.FC<any> = ({ currentRefinement, hits, refine }) => {
  const language = useLanguagePreference();

  // TODO: The commented out rules should be fixed rather than commented out.
  const dropdownItems = [
    {
      label: translate("label_all", language),
      onClick: () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setShowFavoritesOnly(false);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setSelectedDropdownItem(dropdownItems[0]);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setOpenDropdown(false);
      },
    },
    {
      label: translate("tabs_favorites", language),
      onClick: () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setShowFavoritesOnly(true);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setSelectedDropdownItem(dropdownItems[1]);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        setOpenDropdown(false);
      },
    },
  ];

  const {
    data: fData,
    loading: fLoading,
    error: fError,
  } = useMyFavoritesQuery();
  const [favorites, setFavorites] = useState<Business[]>([]);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<Item>(
    dropdownItems[0]
  );

  useEffect(() => {
    setSelectedDropdownItem(dropdownItems[0]);
  }, [language]);

  const isFavorite = useCallback(
    (id: string): boolean => {
      if (fData && fData.me && fData.me.favorites.edges.length > 0) {
        const isFavorite = fData.me.favorites.edges
          .map(edge => edge.node.id)
          .includes(id);
        return isFavorite;
      }

      return false;
    },
    [fData]
  );

  useEffect(() => {
    if (fData && fData.me && fData.me.favorites.edges.length > 0) {
      setFavorites(fData.me.favorites.edges.map(edge => edge.node as Business));
    }
  }, [fData]);

  return (
    <>
      <form className="w-full flex md:ml-0" action="#" method="GET">
        <label htmlFor="search_field" className="sr-only">
          {translate("placeholder_input_search", language)}
        </label>
        <div className="relative w-full text-cool-gray-400 focus-within:text-cool-gray-600">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              />
            </svg>
          </div>
          <input
            id="search_field"
            className="block w-full h-full pl-8 pr-3 py-2 rounded-md text-cool-gray-900 placeholder-cool-gray-500 focus:outline-none focus:placeholder-cool-gray-400 sm:text-sm"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder={translate("placeholder_input_search", language)}
            spellCheck="false"
            maxLength={512}
            type="text"
            value={currentRefinement}
            onChange={event => refine(event.currentTarget.value)}
          />
        </div>
      </form>
      <Portal id="searchResultPortal">
        <div className="pl-4 pr-6 pt-4 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0">
          <div className="flex items-center">
            <h1 className="flex-1 text-lg leading-7 font-medium capitalize">
              {translate("screen_title_organizations", language)}
            </h1>
            <p className="pr-2 capitalize text-base font-medium">
              {translate("label_filter", language)}
            </p>
            <Dropdown
              onClick={() => setOpenDropdown(!openDropdown)}
              open={openDropdown}
              items={dropdownItems}
              selectedItem={selectedDropdownItem}
            />
          </div>
        </div>
        <ul className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
        {
          showFavoritesOnly ?
          favorites.map((obj, index) => {
            return (
              <BusinessCard
                key={index}
                isFavorite={isFavorite(obj.id)}
                business={obj as Business}
              />
            );
          }) :
          hits.map((hit, hitIndex) => {
            // translating index id to business id obj.
            const obj = { ...hit, ...{ id: hit.objectID } };
            return (
              <BusinessCard
                key={hitIndex}
                isFavorite={isFavorite(obj.id)}
                business={obj as Business}
              />
            );
          })
        }
        </ul>
      </Portal>
      <Portal id="modalPortal">
        <LoadingModal show={hits.length < 1} />
      </Portal>
    </>
  );
};

export default connectAutoComplete(SearchBox);
