import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Transition } from "@tailwindui/react";
import tw from "twin.macro";
import { navigate } from "gatsby";
import * as CurrencyFormat from "react-currency-format";

import {
  PaymentMethod,
  useVerifyUserPaymentMethodMutation,
} from "../../generated/graphql";
import Portal from "../portal";
import { translate } from "../../helpers";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import PrimaryButton from "../buttons/primary";

const ValidationSchema = Yup.object().shape({
  id: Yup.string().ensure(),
  amount1: Yup.number()
    .moreThan(0, "Enter a value more than 0.")
    .required("Required"),
  amount2: Yup.number()
    .moreThan(0, "Enter a value more than 0.")
    .required("Required"),
});

interface Props {
  id: string;
}

const VerifyBankAccountForm: React.FC<Props> = ({ id }) => {
  const language = useLanguagePreference();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [
    verify,
    { data: pmData, loading: pmLoading, error: pmError },
  ] = useVerifyUserPaymentMethodMutation();

  useEffect(() => {
    if (pmError) {
      setErrorMessage(pmError.message);
      setShowErrorMessage(true);
    }

    if (
      pmData &&
      pmData.verifyUserPaymentMethod &&
      pmData.verifyUserPaymentMethod.success
    ) {
      navigate("/app/payment-methods");
    }
  }, [pmData, pmLoading, pmError]);

  return (
    <>
      <div className="bg-white py-8 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="sm:w-1/2 mx-auto">
          <Formik
            initialValues={{
              id: id,
              amount1: 0,
              amount2: 0,
            }}
            validationSchema={ValidationSchema}
            onSubmit={async (values, actions) => {
              console.log("VERIFY VALUES:", values);
              // convert amounts to cents
              const first = values.amount1 * 100;
              const second = values.amount2 * 100;
              try {
                const result = await verify({
                  variables: {
                    id: values.id,
                    first,
                    second,
                  },
                });

                actions.setSubmitting(false);

                console.log(result);

                // @TODO -- shouldn't get here. Log if does.
              } catch (error) {
                console.error(error);
                setErrorMessage(error.message);
                setShowErrorMessage(true);
                actions.setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => (
              <Form>
                {/* Amount 1 Field */}
                <div>
                  <label
                    htmlFor="amount1"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate("placeholder_input_amount", language)} 1
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <Field name="amount1">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => {
                        // let formattedValue = formatAmount1(field.value);

                        // setFieldValue('amount1', field.value, true)

                        // if (formattedValue) {
                        //     field.value = formattedValue;
                        // }

                        return (
                          <CurrencyFormat
                            // value={field.value}
                            placeholder={"$1.00"}
                            thousandSeparator={true}
                            prefix={"$"}
                            allowNegative={false}
                            decimalScale={2}
                            // fixedDecimalScale
                            onValueChange={values => {
                              const { formattedValue, value } = values;
                              // formattedValue = $2,223
                              // value ie, 2223
                              // this.setState({ profit: formattedValue })
                              setFieldValue("amount1", value, true);
                            }}
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.amount1
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <ErrorMessage name="amount1">
                    {(msg: string): React.ReactNode => (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="amount1-error"
                      >
                        {msg}
                      </p>
                    )}
                  </ErrorMessage>
                </div>
                {/* Amount 2 Field */}
                <div className="mt-6">
                  <label
                    htmlFor="amount2"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate("placeholder_input_amount", language)} 2
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <Field name="amount2">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }) => {
                        // let formattedValue = formatAmount2(field.value);

                        // setFieldValue('amount2', field.value, true)

                        // if (formattedValue) {
                        //     field.value = formattedValue;
                        // }

                        return (
                          <CurrencyFormat
                            // value={field.value}
                            placeholder={"$1.00"}
                            thousandSeparator={true}
                            prefix={"$"}
                            allowNegative={false}
                            decimalScale={2}
                            // fixedDecimalScale
                            onValueChange={values => {
                              const { formattedValue, value } = values;
                              // formattedValue = $2,223
                              // value ie, 2223
                              // this.setState({ profit: formattedValue })
                              setFieldValue("amount2", value, true);
                            }}
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.amount2
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                            }`}
                          />
                        );
                      }}
                    </Field>
                  </div>
                  <ErrorMessage name="amount2">
                    {(msg: string): React.ReactNode => (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="amount2-error"
                      >
                        {msg}
                      </p>
                    )}
                  </ErrorMessage>
                </div>
                <div className="mt-6">
                  <PrimaryButton
                    title={translate("button_verify", language)}
                    type="submit"
                    disabled={isSubmitting || pmLoading}
                    additionalButtonClasses="group relative w-full flex justify-center"
                    icon={
                      (isSubmitting || pmLoading) && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )
                    }
                  />
                  {/* <button
                    
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      
                    </span>
                    
                  </button> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Portal id="modalPortal">
        {/* Error Message Modal */}
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          css={[!showErrorMessage && tw`hidden`]}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* <!--
                        Background overlay, show/hide based on modal state.

                        Entering: "ease-out duration-300"
                        From: "opacity-0"
                        To: "opacity-100"
                        Leaving: "ease-in duration-200"
                        From: "opacity-100"
                        To: "opacity-0"
                    --> */}
            <Transition
              show={showErrorMessage}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
            </Transition>
            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span className="hidden sm:align-middle sm:h-screen"></span>&#8203;
            {/* <!--
                            Modal panel, show/hide based on modal state.

                            Entering: "ease-out duration-300"
                            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            To: "opacity-100 translate-y-0 sm:scale-100"
                            Leaving: "ease-in duration-200"
                            From: "opacity-100 translate-y-0 sm:scale-100"
                            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        --> */}
            <Transition
              show={showErrorMessage}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    aria-label="Close"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {translate("error_mutation_verify_bank", language)}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm leading-5 text-gray-600">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {/* <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                                    <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                        Deactivate
                                    </button>
                                    </span> */}
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      type="button"
                      onClick={() => setShowErrorMessage(false)}
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      {translate("overlay_button_close", language)}
                    </button>
                  </span>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Portal>
    </>
  );
};

export default VerifyBankAccountForm;
