import { useEffect, useState } from "react";
import { useMeQueryQuery } from "../generated/graphql";

const useLanguagePreference = (): string => {
  const { data, error, loading } = useMeQueryQuery();
  const [language, setLanguage] = useState<string>("en");

  useEffect(() => {
    if (data && data.me && data.me.profile) {
      console.log(`Language: ${data.me.profile.language}`);
      setLanguage(data.me.profile.language);
    }

    if (loading) {
      //
    }

    if (error) {
      //
    }

    return () => {
      // unsubscribe
    };
  }, [data, loading, error]);

  return language;
};

export default useLanguagePreference;
