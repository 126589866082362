import React from "react";
import { RouteComponentProps, useParams, Link, navigate } from "@reach/router";

import Layout from "../../layouts/main";
import { useGetPaymentMethodsQuery } from "../../generated/graphql";
import PageHeader from "../../layouts/pageHeader";
import PageContent from "../../layouts/pageContent";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import { translate } from "../../helpers";
import VerifyBankAccountForm from "../../components/forms/verifyBankAccount";
import GoBackButton from "../../components/buttons/goBack";

const Verify: React.FC<RouteComponentProps> = () => {
  const language = useLanguagePreference();
  const params = useParams();

  return (
    <Layout>
      <PageHeader
        title={translate("screen_title_payment_method_verification", language)}
      >
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <GoBackButton url="/app/payment-methods" />
        </div>
      </PageHeader>
      <PageContent>
        {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
        <div className="rounded-md bg-yellow-50 p-4 mb-8">
            <div className="flex">
              <div className="flex-shrink-0">
                {/* <!-- Heroicon name: solid/exclamation --> */}
                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800 capitalize">
                  {translate("attention", language)}
                </h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    {translate("attention_verify_1", language)} <strong>{translate("attention_verify_2", language)}</strong>{translate("attention_verify_3", language)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        <VerifyBankAccountForm id={params.id} />
      </PageContent>
    </Layout>
  );
};

export default Verify;
