import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import sortBy from "lodash/sortBy";
import moment from "moment";

import Layout from "../../layouts/main";
import {
  useGetPaymentMethodsQuery,
  PaymentMethod,
  useRemoveUserPaymentMethodMutation,
  GetPaymentMethodsDocument,
  GetPaymentMethodsQuery,
  PaymentMethodEdge,
  useMeQueryQuery
} from "../../generated/graphql";
import PageHeader from "../../layouts/pageHeader";
import PageContent from "../../layouts/pageContent";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import { translate, isPendingBankAccount } from "../../helpers";
import PaymentMethodCard from "../../components/cards/paymentMethod";
import PrimaryButton from "../../components/buttons/primary";
import ConfirmationModal from "../../components/modals/confirmation";
import LoadingModal from "../../components/modals/loading";
import ErrorModal from "../../components/modals/error";
import Portal from "../../components/portal";
import UpdatedAlert from "../../components/alerts/updated";
import SecondaryButton from "../../components/buttons/secondary";

interface Props extends RouteComponentProps {
  state: {
    newId: string;
  }
}

const IndexPage: React.FC<Props> = ({ location }) => {
  const language = useLanguagePreference();

  const [paymentMethodList, setPaymentMethodList] = useState<{link: string; pm: PaymentMethod}[]>([]);

  const {
    data,
    loading,
    error,
    refetch,
    networkStatus,
    fetchMore,
  } = useMeQueryQuery();

  const [
    removePM,
    { data: rData, loading: rLoading, error: rError },
  ] = useRemoveUserPaymentMethodMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      if (location.state.hasOwnProperty("newId")) {
        console.log('State:', location.state["newId"]);
        refetch();
      }
    }
  }, [location]);

  useEffect(() => {

    if (data && data.me && data.me.paymentMethods.edges.length > 0) {
      setPaymentMethodList(sortBy(data.me.paymentMethods.edges, [
        item => moment(item.node.createdAt),
      ]).map((edge, i) => {
          const pm = edge.node;
          let link = `/app/payment-methods/${edge.cursor}`;

          if (isPendingBankAccount(pm)) {
            link = link + `/verify/${pm.id}`;
          }

          return { link, pm };
      }));
    }

  }, [data]);

  return (
    <Layout>
      <PageHeader title={translate("screen_title_payment_methods", language)}>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <SecondaryButton
            title={translate("button_add", language)}
            url={"add"}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="-ml-1 mr-2 h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            }
          />
          <PrimaryButton
            title={translate("button_done", language)}
            url="/app"
            additionalContainerClasses="ml-3"
            icon={
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
          />
        </div>
      </PageHeader>
      {/* <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
--> */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
        <div className="rounded-md bg-yellow-50 p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              {/* <!-- Heroicon name: solid/exclamation --> */}
              <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800 capitalize">
                {translate("attention", language)}
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                {translate("attention_pm_index_1", language)} <strong>{translate("attention_pm_index_2", language)}</strong>{translate("attention_pm_index_3", language)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {paymentMethodList.map((edge, i) => {
            const { link, pm } = edge;
              return (
                <li key={i} className="col-span-1 bg-white rounded-lg shadow">
                  <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="text-gray-900 text-sm leading-5 font-medium truncate">
                          {pm.nickName}
                        </h3>
                        {data.me.profile.primaryPaymentMethod &&
                          data.me.profile.primaryPaymentMethod.id === pm.id && (
                            <span className="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full capitalize">
                              {translate("default", language)}
                            </span>
                          )}
                        {isPendingBankAccount(pm) && (
                          <span className="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs leading-4 font-medium bg-yellow-100 rounded-full capitalize">
                            {translate(
                              "warning_pending_verification",
                              language
                            )}
                          </span>
                        )}
                      </div>
                      <p className="mt-1 text-gray-500 text-sm leading-5 truncate">
                        {pm.bankName || pm.brand}...{pm.last4}
                      </p>
                    </div>
                    {/* <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=4&amp;w=256&amp;h=256&amp;q=60" alt="" /> */}
                  </div>
                  <div className="border-t border-gray-200">
                    <div className="-mt-px flex">
                      <div className="w-0 flex-1 flex border-r border-gray-200">
                        <button
                          type="button"
                          className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                          onClick={() => {
                            setPaymentMethod(pm);
                            setShowConfirmation(true);
                          }}
                        >
                          {/* <!-- Heroicon name: mail --> */}
                          {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg> */}
                          <svg
                            className="w-5 h-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          <span className="ml-3 capitalize">
                            {translate("button_remove", language)}
                          </span>
                        </button>
                      </div>
                      <div className="-ml-px w-0 flex-1 flex">
                        <Link
                          to={link}
                          className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
                        >
                          {/* <!-- Heroicon name: phone --> */}
                          {/* <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg> */}
                          <svg
                            className="w-5 h-5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                          <span className="ml-3 capitalize">
                            {isPendingBankAccount(pm)
                              ? translate("verify", language)
                              : translate("edit", language)}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
        {paymentMethodList.length === 0 && (
            <>
              <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-2xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
                  {translate("empty_payment_methods_title", language)}
                  <br />
                  <span className="text-teal-600">
                    {translate("empty_payment_methods_sub_title_1", language)}
                  </span>
                </h2>
              </div>
            </>
          )}
      </div>
      <Portal id="notificationPortal">
        <UpdatedAlert
          show={showAlert}
          message={translate("generic_success_message", language)}
          onClose={() => setShowAlert(false)}
          type="success"
        />
      </Portal>
      <Portal id="modalPortal">
        <LoadingModal show={loading} />
        <ErrorModal
          msg={errorMessage}
          show={showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
        />
        <ConfirmationModal
          msg={translate("confirm_message_remove_pm", language)}
          show={showConfirmation}
          onConfirm={async () => {
            if (paymentMethod) {
              const result = await removePM({
                variables: {
                  id: paymentMethod.id,
                },
              });

              if (result.errors) {
                setErrorMessage(result.errors.toString());
                setShowErrorMessage(true);
                return;
              }

              if (
                result.data.removeUserPaymentMethod &&
                result.data.removeUserPaymentMethod.success
              ) {
                setShowConfirmation(false);
                setShowAlert(true);
              }
            }
          }}
          onCancel={() => setShowConfirmation(false)}
          loading={rLoading}
        />
      </Portal>
      {/* <PageContent>
        <ul>
          {data &&
            data.me &&
            sortBy(data.me.paymentMethods.edges, [
              item => moment(item.node.createdAt),
            ]).map((edge, i) => {
              const pm = edge.node;
              let link = `/app/payment-methods/${edge.cursor}`;

              if (isPendingBankAccount(pm)) {
                link = link + `/verify/${pm.id}`;
                console.log(link);
              }

              return (
                <li key={i} className="border-t border-gray-200">
                  <Link
                    to={link}
                    className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
                  >
                    <PaymentMethodCard
                      isPrimary={
                        data.me.profile.primaryPaymentMethod &&
                        data.me.profile.primaryPaymentMethod.id === pm.id
                      }
                      paymentMethod={pm}
                    />
                  </Link>
                </li>
              );
            })}
        </ul>
        {!data ||
          !data.me ||
          (data.me.paymentMethods.edges.length === 0 && (
            <>
              <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-2xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
                  {translate("empty_payment_methods_title", language)}
                  <br />
                  <span className="text-teal-600">
                    {translate("empty_payment_methods_sub_title_1", language)}
                  </span>
                </h2>
              </div>
            </>
          ))}
      </PageContent> */}
    </Layout>
  );
};

export default IndexPage;
