import React, { useState, useEffect, useCallback, useMemo } from "react";
import { RouteComponentProps, useParams, Link, navigate } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { components } from 'react-select';
import * as CurrencyFormat from "react-currency-format";

import Layout from "../../layouts/main";
import {
  useMyBusinessQueryQuery,
  PaymentMethod,
  useGetPaymentMethodsQuery,
  useMakeTransactionMutation,
  Business,
  Purpose,
  GetMyTransactionsDocument,
  GetMyTransactionsQuery,
  Transaction,
  TransactionEdge,
} from "../../generated/graphql";
import { formatAmount, translate } from "../../helpers";
import { v4 as uuidv4 } from "uuid";
import Portal from "../../components/portal";
import ConfirmationModal from "../../components/modals/confirmation";
import LoadingModal from "../../components/modals/loading";
import PageHeader from "../../layouts/pageHeader";
import PageContent from "../../layouts/pageContent";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import GoBackButton from "../../components/buttons/goBack";
import PrimaryButton from "../../components/buttons/primary";

type Option = { label: string; value: string; node?: any };
type VariableValueType = {
  paymentMethod: PaymentMethod;
  destination: Business;
  amount: number;
  purpose: Purpose;
  notes: string;
  key: string;
};

const SearchGive: React.FC<RouteComponentProps> = () => {
  const language = useLanguagePreference();

  const [ValidationSchema, setValidationSchema] = useState<any>(
    Yup.object().shape({
      paymentMethod: Yup.string().required(
        translate("warning_required", language)
      ),
      paymentMethodType: Yup.string().required(),
      amount: Yup.number()
        .min(1, translate("warning_min_amount", language))
        .when("paymentMethodType", {
          is: val => val == "bank_account",
          then: Yup.number().max(
            2000.0,
            translate("warning_amount_limit", language)
          ),
          otherwise: Yup.number().max(
            999999.99,
            "Maximum allowed $999,999.99."
          ),
        })
        .positive(translate("warning_positive_amount", language))
        .required(translate("warning_required", language)),
      purpose: Yup.string().required(translate("warning_required", language)),
      notes: Yup.string().ensure(),
    })
  );

  useEffect(() => {
    setValidationSchema(
      Yup.object().shape({
        paymentMethod: Yup.string().required(
          translate("warning_required", language)
        ),
        paymentMethodType: Yup.string().required(),
        amount: Yup.number()
          .min(1, translate("warning_min_amount", language))
          .when("paymentMethodType", {
            is: val => val == "bank_account",
            then: Yup.number().max(
              2000.0,
              translate("warning_amount_limit", language)
            ),
            otherwise: Yup.number().max(
              999999.99,
              translate("warning_amount_limit_default", language)
            ),
          })
          .positive(translate("warning_positive_amount", language))
          .required(translate("warning_required", language)),
        purpose: Yup.string().required(translate("warning_required", language)),
        notes: Yup.string().ensure(),
      })
    );
  }, [language]);

  const params = useParams();
  const [paymentMethods, setPaymentMethods] = useState<Option[]>([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<Option>();
  const [purposes, setPurposes] = useState<Option[]>([]);
  const [selectedPurpose, setSelectedPurpose] = useState<Option>();
  const [variableValues, setVariableValues] = useState<VariableValueType>();
  const [
    makeTransaction,
    { data: mData, loading: mLoading, error: mError },
  ] = useMakeTransactionMutation({
    update(cache, { data }) {
      const { transaction: newTransaction } = data.makeTransaction;
      const { me } = cache.readQuery<GetMyTransactionsQuery>({
        query: GetMyTransactionsDocument,
      });

      const newEdge = {
        cursor: "",
        node: newTransaction as Transaction,
      } as TransactionEdge;

      const newEdges = [newEdge, ...me.transactions.edges];

      const updatedMe = {
        ...me,
        ...{ edges: newEdges },
      };

      cache.writeQuery({
        query: GetMyTransactionsDocument,
        data: { me: updatedMe },
      });
    },
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
    false
  );
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { data, loading, error } = useMyBusinessQueryQuery({
    variables: {
      id: params.businessId,
    },
  });

  useEffect(() => {
    if (data && data.business && data.business.purposes) {
      const { purposes } = data.business;
      const pList: Option[] = purposes.edges.map(edge => {
        const { node } = edge;
        return {
          label: translate(`purpose_${node.name.toLowerCase()}`, language),
          value: node.id,
          node,
        };
      });

      setPurposes(pList);
    }
  }, [data, error, language]);

  const {
    data: pData,
    loading: pLoading,
    error: pError,
  } = useGetPaymentMethodsQuery();

  useEffect(() => {
    if (pData && pData.me.paymentMethods) {
      const pmList: Option[] = pData.me.paymentMethods.edges.map(edge => {
        const { node } = edge;
        return { label: node.nickName, value: node.id, node };
      });

      setPaymentMethods(pmList);
    }

    if (pData && pData.me.profile && pData.me.profile.primaryPaymentMethod) {
      const pm = pData.me.profile.primaryPaymentMethod;
      setDefaultPaymentMethod({ label: pm.nickName, value: pm.id, node: pm });
    }
  }, [pData, pError]);

  const confirmGive = useCallback(async () => {
    setShowConfirmationModal(false);
    try {
      const result = await makeTransaction({
        variables: {
          destination: variableValues.destination.id,
          paymentMethodId: variableValues.paymentMethod.id,
          purpose: variableValues.purpose.id,
          amount: variableValues.amount,
          key: variableValues.key,
          notes: variableValues.notes,
        },
      });

      // failure
      if (result.errors) {
        setErrorMessage(result.errors.toString());
        setShowErrorMessage(true);
        return;
      }

      // success
      if (result.data && result.data.makeTransaction) {
        navigate(`/app/`);
        return;
      }
    } catch (error) {
      console.error(error);
      // stop loading
    }
  }, [variableValues]);

  return (
    <Layout>
      <PageHeader title={translate("screen_title_give_info", language)}>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <GoBackButton url={`/app/search/${params.businessId}`} />
        </div>
      </PageHeader>
      <PageContent>
        <div className="max-w-4xl mx-auto">
          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            {data && data.business && (
              <h3 className="text-lg leading-6 font-medium text-gray-900 relative">
                <span className="inline-flex items-center">
                  {/*<svg
                    className="flex-shrink-0 w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                    />
                  </svg>*/}
                  <span className="text-2xl">
                    {data.business.friendlyName ? data.business.friendlyName : data.business.name}
                  </span>
                </span>
              </h3>
            )}
            {data && data.business && (
              <p className="mt-2 max-w-2xl text-base leading-5 text-gray-600">
                ID <span>&middot;</span> {data.business.handle}
              </p>
            )}
            {data && data.business && data.business.address && (
              <address className="flex items-center mt-2 max-w-2xl text-sm leading-5 text-gray-600">
                <svg
                  className="flex-shrink-0 w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-1">
                  <span>{data.business.address.street1}</span> {` `}
                  {data.business.address.street2 && (
                    <span>{data.business.address.street2}</span>
                  )}
                  <br />
                  <span>
                    {data.business.address.city} {data.business.address.zipcode}
                  </span>
                </span>
              </address>
            )}
            {data &&
              data.business &&
              data.business.manager &&
              data.business.manager.profile && (
                <p className="flex items-center mt-2 max-w-2xl text-sm leading-5 text-gray-600">
                  <svg
                    className="flex-shrink-0 w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="ml-1">
                    {data.business.manager.profile.firstName}{" "}
                    {data.business.manager.profile.lastName}
                  </span>
                </p>
              )}
            <Formik
              initialValues={{
                paymentMethod: "",
                paymentMethodType: "",
                amount: 0,
                purpose: "",
                notes: "",
                key: uuidv4(),
              }}
              validationSchema={ValidationSchema}
              onSubmit={async (values, actions) => {
                if (data && data.business) {
                  setVariableValues({
                    paymentMethod: defaultPaymentMethod.node,
                    destination: data.business as Business,
                    amount: values.amount,
                    purpose: selectedPurpose.node,
                    notes: values.notes,
                    key: values.key,
                  });

                  // open confirm modal and pass in callback function
                  setShowConfirmationModal(true);
                }

                actions.setSubmitting(false);
              }}
            >
              {({ isSubmitting, errors, values, setFieldValue }) => {

                useEffect(() => {
                  if (defaultPaymentMethod) {
                    setFieldValue("paymentMethod", defaultPaymentMethod.value, true);
                    setFieldValue(
                      "paymentMethodType",
                      defaultPaymentMethod.value,
                      true
                    );
                  }
                }, [defaultPaymentMethod]);

                return (
                <Form className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8 mt-4">
                  {/* Payment Methods */}
                  <div className="sm:col-span-2">
                    {paymentMethods.length > 0 ?
                    <>
                    <label
                      htmlFor="paymentMethod"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      {translate("label_payment_method", language)}
                    </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <Select
                          placeholder={translate(
                            "placeholder_input_purpose_subtext",
                            language
                          )}
                          defaultValue={defaultPaymentMethod ? paymentMethods[paymentMethods.findIndex((pm) => pm.value == defaultPaymentMethod.value)] : null}
                          options={paymentMethods}
                          onChange={(option: Option) => {
                            setDefaultPaymentMethod(option);
                          }}
                        />
                      </div>
                      </>
                    :
                      <Link className="shadow-md inline-flex items-center px-6 py-2 sm:py-4 border border-transparent text-sm sm:text-lg leading-5 font-semibold rounded-md text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:shadow-outline-teal focus:border-teal-700 active:bg-teal-700 transition duration-150 ease-in-out group relative flex justify-center" to="/app/payment-methods/add">
                        {translate("label_no_pm", language)}
                      </Link>
                    }
                    <ErrorMessage name="paymentMethod">
                      {(msg: string): React.ReactNode => (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="paymentMethod-error"
                        >
                          {msg}
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                  {/* Amount */}
                  <div>
                    <label
                      htmlFor="amount"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      {translate("placeholder_input_amount", language)}
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <Field name="amount">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta,
                        }) => {
                          // let formattedValue = formatAmount(field.value);

                          // setFieldValue('amount', field.value, true)

                          // if (formattedValue) {
                          //     field.value = formattedValue;
                          // }

                          return (
                            <CurrencyFormat
                              // value={field.value}
                              placeholder={"$1.00"}
                              thousandSeparator={true}
                              prefix={"$"}
                              allowNegative={false}
                              decimalScale={2}
                              // fixedDecimalScale
                              onValueChange={values => {
                                const { formattedValue, value } = values;
                                // formattedValue = $2,223
                                // value ie, 2223
                                // this.setState({ profit: formattedValue })
                                setFieldValue("amount", value, true);
                              }}
                              className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                                errors.amount
                                  ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                  : ""
                              }`}
                            />
                          );
                        }}
                      </Field>
                    </div>
                    <ErrorMessage name="amount">
                      {(msg: string): React.ReactNode => (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="amount-error"
                        >
                          {msg}
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                  {/* Purpose */}
                  <div>
                    <label
                      htmlFor="purpose"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      {translate("placeholder_input_purpose", language)}
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <Select
                        options={purposes}
                        onChange={(option: Option) => {
                          setFieldValue("purpose", option.value, true);
                          setSelectedPurpose(option);
                        }}
                      />
                    </div>
                    <ErrorMessage name="purpose">
                      {(msg: string): React.ReactNode => (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="purpose-error"
                        >
                          {msg}
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                  {/* Notes */}
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="notes"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      {translate("placeholder_input_notes", language)}
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      {/* <input id="notes" type="notes" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                      <Field
                        as="textarea"
                        name="notes"
                        className={`form-textarea py-3 px-4 block w-full transition ease-in-out duration-150 ${
                          errors.notes
                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                            : ""
                        }`}
                        //   placeholder="you@example.com"
                        //   autoComplete={'notes'}
                      />
                    </div>
                    <ErrorMessage name="notes">
                      {(msg: string): React.ReactNode => (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="notes-error"
                        >
                          {msg}
                        </p>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="mt-4 sm:col-span-2">
                    <PrimaryButton
                      title={translate("button_send", language)}
                      type="submit"
                      disabled={isSubmitting}
                      additionalButtonClasses="group relative w-full flex justify-center"
                      icon={
                        isSubmitting && (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )
                      }
                    />
                    {/* <button

                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"

                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">

                      </span>

                    </button> */}
                  </div>
                  <Portal id="modalPortal">
                    <LoadingModal
                      show={mLoading || loading || pLoading}
                      globalIcon={
                        <svg
                          className="animate-spin h-10 w-10 text-teal-800"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      }
                    />
                    <ConfirmationModal
                      msg={translate("overlay_review", language)}
                      show={showConfirmationModal}
                      globalIcon={
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <svg
                            className="h-6 w-6 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                      }
                      footer={
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                            <button
                              type="button"
                              onClick={() => confirmGive()}
                              className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-teal-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-teal-500 focus:outline-none focus:border-teal-700 focus:shadow-outline-teal transition ease-in-out duration-150 sm:text-sm sm:leading-5 capitalize"
                            >
                              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                {(isSubmitting || mLoading) && (
                                  <svg
                                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                )}
                              </span>
                              {translate("button_send", language)}
                            </button>
                          </span>
                          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                            <button
                              type="button"
                              onClick={() => setShowConfirmationModal(false)}
                              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:text-white focus:outline-none focus:border-gray-300 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5 capitalize"
                            >
                              {translate("button_cancel", language)}
                            </button>
                          </span>
                        </div>
                      }
                    >
                      <div className="px-4 py-5 sm:p-0">
                        <dl>
                          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                            <dt className="text-sm sm:text-base leading-5 font-medium text-gray-500 capitalize">
                              {translate("confirmation_give_to", language)}
                            </dt>
                            {variableValues && (
                              <dd className="mt-1 text-sm sm:text-base leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {variableValues.destination.friendlyName ? variableValues.destination.friendlyName : variableValues.destination.name}
                              </dd>
                            )}
                          </div>
                          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm sm:text-base leading-5 font-medium text-gray-500 capitalize">
                              {translate("placeholder_input_amount", language)}
                            </dt>
                            {variableValues && (
                              <dd className="mt-1 text-sm sm:text-base leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {formatAmount(variableValues.amount)}
                              </dd>
                            )}
                          </div>
                          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm sm:text-base leading-5 font-medium text-gray-500 capitalize">
                              {translate("placeholder_input_purpose", language)}
                            </dt>
                            {variableValues && variableValues.purpose && (
                              <dd className="mt-1 capitalize text-sm sm:text-base leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {variableValues.purpose.name}
                              </dd>
                            )}
                          </div>
                          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm sm:text-base leading-5 font-medium text-gray-500 capitalize">
                              {translate("label_payment_method", language)}
                            </dt>
                            {variableValues && (
                              <dd className="mt-1 text-sm sm:text-base leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {variableValues.paymentMethod.nickName}
                              </dd>
                            )}
                          </div>
                          <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                            <dt className="text-sm sm:text-base leading-5 font-medium text-gray-500 capitalize">
                              {translate("placeholder_input_notes", language)}
                            </dt>
                            {variableValues && (
                              <dd className="mt-1 text-sm sm:text-base leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                {variableValues.notes}
                              </dd>
                            )}
                          </div>
                        </dl>
                      </div>
                    </ConfirmationModal>
                  </Portal>
                </Form>
              )}}
            </Formik>
          </div>
        </div>
      </PageContent>
    </Layout>
  );
};

export default SearchGive;
