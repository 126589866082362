import React from "react";

export type Item = { label: string; onClick: (event) => void };

interface Props {
  open: boolean;
  onClick: (event) => void;
  items: Item[];
  selectedItem: Item;
}

const Dropdown: React.FC<Props> = ({ open, onClick, items, selectedItem }) => {
  return (
    <div className="relative">
      <span className="rounded-md shadow-sm">
        <button
          id="sort-menu"
          onClick={onClick}
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 capitalize"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedItem.label}
          {open && (
            <svg
              className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
              x-description="Heroicon name: sort-ascending"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"></path>
            </svg>
          )}
          {!open && (
            <svg
              className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
              x-description="Heroicon name: chevron-down"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          )}
        </button>
      </span>
      {open && (
        <div className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg">
          <div className="rounded-md bg-white shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="sort-menu"
            >
              {items.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={item.onClick}
                  className="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 capitalize"
                  role="menuitem"
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
