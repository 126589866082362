import React, { ReactChild } from "react";
import { Transition } from "@tailwindui/react";
import tw from "twin.macro";
import { css } from "@emotion/core";
import { translate } from "../../helpers";
import useLanguagePreferences from "../../hooks/useLanguagePreference";

interface Props extends React.HTMLAttributes<HTMLElement> {
  msg: string;
  subMsg?: string;
  show: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  showCloseIcon?: boolean;
  globalIcon?: ReactChild;
  footer?: ReactChild;
  loading?: boolean;
}

const ConfirmationModal: React.FC<Props> = ({
  show,
  msg,
  onConfirm,
  onCancel,
  showCloseIcon = false,
  subMsg,
  globalIcon,
  footer,
  loading,
  children,
}) => {
  const language = useLanguagePreferences();
  return (
    <>
      {/* Error Message Modal */}
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        css={[!show && tw`hidden`]}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!--
                        Background overlay, show/hide based on modal state.

                        Entering: "ease-out duration-300"
                        From: "opacity-0"
                        To: "opacity-100"
                        Leaving: "ease-in duration-200"
                        From: "opacity-100"
                        To: "opacity-0"
                    --> */}
          <Transition
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
          </Transition>
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:align-middle sm:h-screen"></span>&#8203;
          {/* <!--
                            Modal panel, show/hide based on modal state.

                            Entering: "ease-out duration-300"
                            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            To: "opacity-100 translate-y-0 sm:scale-100"
                            Leaving: "ease-in duration-200"
                            From: "opacity-100 translate-y-0 sm:scale-100"
                            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        --> */}
          <Transition
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-64 md:w-full sm:p-6 max-w-lg"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {showCloseIcon && (
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    aria-label="Close"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
              <div className="sm:flex sm:items-center sm:flex-col">
                {globalIcon && <>{globalIcon}</>}
                <div className="mt-3 text-center sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    {msg}
                  </h3>
                  {subMsg && (
                    <div className="mt-2">
                      <p className="text-sm leading-5 text-gray-600">
                        {subMsg}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {children && <>{children}</>}
              {footer && <>{footer}</>}
              {!footer && (
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button
                      type="button"
                      onClick={onConfirm}
                      className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 capitalize"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        {loading && (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        )}
                      </span>
                      {translate("button_yes", language)}
                    </button>
                  </span>
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      type="button"
                      onClick={onCancel}
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-gray-600 text-base leading-6 font-medium text-white shadow-sm hover:text-white focus:outline-none focus:border-gray-300 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5 capitalize"
                    >
                      {translate("button_cancel", language)}
                    </button>
                  </span>
                </div>
              )}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
