import React from "react";
import { Link } from "@reach/router";

import { translate } from "../../helpers";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import SecondaryButton from "./secondary";

interface Props {
  url: string;
}

const GoBackButton: React.FC<Props> = ({ url }) => {
  const language = useLanguagePreference();

  return (
    <SecondaryButton
      title={translate("button_back", language)}
      url={url}
      icon={
        <svg
          className="-ml-1 mr-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
      }
    />
    // <span className="shadow-sm rounded-md">
    //   <Link
    //     to={url}
    //     className="inline-flex items-center px-6 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out capitalize"
    //   >

    //   </Link>
    // </span>
  );
};

export default GoBackButton;
