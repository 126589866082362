import React from "react";
import { ApolloProvider } from "@apollo/client";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import client from "../../gatsby-theme-apollo/client";

const Root: React.FC = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {children}
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
};

export default Root;
