import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps, Link } from "@reach/router";

import {
  useGetPaymentMethodsQuery,
  PaymentMethod,
} from "../../generated/graphql";
import Layout from "../../layouts/main";
import UpdateBankAccountForm from "../../components/forms/updateBankAccount";
import UpdateCreditDebitCardForm from "../../components/forms/updateCreditDebitCard";
import PageHeader from "../../layouts/pageHeader";
import PageContent from "../../layouts/pageContent";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import { translate } from "../../helpers";
import GoBackButton from "../../components/buttons/goBack";

interface UpdatePageProps extends RouteComponentProps {
  paymentMethod?: string;
}

const UpdatePage: React.FC<UpdatePageProps> = props => {
  const language = useLanguagePreference();
  const { data, loading, error } = useGetPaymentMethodsQuery({
    fetchPolicy: "cache-only",
    onCompleted: data => {
      console.log("COMPLETED:", data);
    },
  });

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(null);

  useEffect(() => {
    if (data && data.me && data.me.paymentMethods) {
      for (let i = 0; i < data.me.paymentMethods.edges.length; i++) {
        const pm = data.me.paymentMethods.edges[i];
        if (pm.cursor === props.paymentMethod) {
          setPaymentMethod(pm.node);
        }
      }
    }
  }, [data]);

  const isDefault = useCallback(
    (paymentMethod: PaymentMethod): boolean => {
      if (data.me && data.me.profile && data.me.profile.primaryPaymentMethod) {
        return data.me.profile.primaryPaymentMethod.id === paymentMethod.id;
      }

      return false;
    },
    [data]
  );

  return (
    <Layout>
      <PageHeader
        title={
          paymentMethod
            ? `${
                paymentMethod.bankName
                  ? paymentMethod.bankName
                  : paymentMethod.brand
              }...${paymentMethod.last4}`
            : ""
        }
      >
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <GoBackButton url={"/app/payment-methods"} />
        </div>
      </PageHeader>
      <PageContent>
        {/* Bank Account Tab */}
        {paymentMethod && paymentMethod.type === "bank_account" && (
          <UpdateBankAccountForm
            paymentMethod={paymentMethod}
            isDefault={isDefault(paymentMethod)}
          />
        )}
        {/* Debit/Credit Card Tab */}
        {paymentMethod && paymentMethod.type != "bank_account" && (
          <UpdateCreditDebitCardForm
            paymentMethod={paymentMethod}
            isDefault={isDefault(paymentMethod)}
          />
        )}
      </PageContent>
    </Layout>
  );
};

export default UpdatePage;
