import React, { useState } from "react";
import { RouteComponentProps, Link } from "@reach/router";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Layout from "../layouts/main";
import PageHeader from "../layouts/pageHeader";
import PageContent from "../layouts/pageContent";
import {
  useUpdateUserPreferencesMutation,
  useMeQueryQuery,
} from "../generated/graphql";
import Portal from "../components/portal";
import LoadingModal from "../components/modals/loading";
import ErrorModal from "../components/modals/error";
import UpdatedAlert from "../components/alerts/updated";
import { translate } from "../helpers";
import useLanguagePreference from "../hooks/useLanguagePreference";
import GoBackButton from "../components/buttons/goBack";
import PrimaryButton from "../components/buttons/primary";

const ValidationSchema = Yup.object().shape({
  language: Yup.string()
    .required("Required")
    .oneOf(["en", "es"], "Invalid value."),
});

const PreferencesPage: React.FC<RouteComponentProps> = () => {
  const language = useLanguagePreference();
  const { data, loading, error } = useMeQueryQuery({
    fetchPolicy: "cache-only",
  });
  const [
    update,
    { data: uData, loading: uLoading, error: uError },
  ] = useUpdateUserPreferencesMutation();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showAlert, setShowAlert] = useState(false);

  return (
    <Layout>
      <PageHeader title={translate("screen_title_preferences", language)}>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <GoBackButton url={`/app`} />
        </div>
      </PageHeader>
      <PageContent>
        <div className="py-8 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
          <div className="sm:w-2/3 mx-auto">
            {data && data.me && data.me.profile && (
              <Formik
                initialValues={{
                  language: data.me.profile.language ?? "en",
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                  try {
                    const result = await update({
                      variables: {
                        language: values.language,
                      },
                    });
                    actions.setSubmitting(false);

                    if (result.errors) {
                      setErrorMessage(result.errors.toString());
                      setShowErrorMessage(true);
                      return;
                    }

                    if (
                      result.data &&
                      result.data.updateUserPreferences &&
                      result.data.updateUserPreferences.success
                    ) {
                      setShowAlert(true);
                      return;
                    }
                    // @TODO -- shouldn't get here. Log if does.
                  } catch (error) {
                    console.error(error);
                    setErrorMessage(error.message);
                    setShowErrorMessage(true);
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({ isSubmitting }) => (
                  <Form className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8">
                    <div>
                      <label
                        htmlFor="location"
                        className="block text-sm leading-5 font-medium text-gray-700"
                      >
                        {translate("preferences_language", language)}
                      </label>
                      <Field
                        as="select"
                        name="language"
                        className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                      >
                        <option value="en">English</option>
                        <option value="es">Español</option>
                      </Field>
                    </div>
                    <div className="sm:col-span-2">
                      <PrimaryButton
                        title={translate("button_update", language)}
                        icon={
                          isSubmitting && (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )
                        }
                        additionalContainerClasses={"w-full inline-flex"}
                        additionalButtonClasses={
                          "w-full inline-flex items-center justify-center"
                        }
                        disabled={isSubmitting}
                        type="submit"
                      />
                      {/* <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          type="submit"
                          className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                          
                        >
                          
                          
                        </button>
                      </span> */}
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </PageContent>
      <Portal id="notificationPortal">
        <UpdatedAlert
          show={showAlert}
          message={translate("statement_updated_success", language)}
          onClose={() => setShowAlert(false)}
          type="success"
        />
      </Portal>
      <Portal id="modalPortal">
        <LoadingModal show={loading} />
        <ErrorModal
          msg={errorMessage}
          show={showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
        />
      </Portal>
    </Layout>
  );
};

export default PreferencesPage;
