import React, { useEffect, useState } from "react";
import { RouteComponentProps, Link } from "@reach/router";

import Layout from "../../layouts/main";
import {
  useGetMyTransactionsQuery,
  Transaction,
} from "../../generated/graphql";
import PageHeader from "../../layouts/pageHeader";
import GoBackButton from "../../components/buttons/goBack";
import PageContent from "../../layouts/pageContent";
import { translate, formatAmount, formatDate } from "../../helpers";
import useLanguagePreference from "../../hooks/useLanguagePreference";

interface Props extends RouteComponentProps {
  transactionId?: string;
}

const TransactionInfoPage: React.FC<Props> = ({ transactionId }) => {
  const [transaction, setTransaction] = useState<Transaction>();
  const language = useLanguagePreference();

  const {
    data,
    loading,
    error,
    refetch,
    networkStatus,
    fetchMore,
  } = useGetMyTransactionsQuery({
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data && data.me && data.me.transactions) {
      const { transactions } = data.me;
      transactions.edges.map((edge, i) => {
        const { node } = edge;
        if (node.id === transactionId) {
          setTransaction(node as Transaction);
        }
      });
    }
  }, [data]);

  return (
    <Layout>
      <PageHeader title={translate("table_header_transaction", language)}>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <GoBackButton url="/app/" />
        </div>
      </PageHeader>
      <PageContent>
        <div className="px-4 py-5 sm:px-6">
          {transaction && (
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-base capitalize leading-5 text-gray-500">
                  {translate("label_destination", language)}
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                  @ {transaction.destination.handle}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-base capitalize leading-5 text-gray-500">
                  {translate("table_header_amount", language)}
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                  {formatAmount(transaction.amount)} USD
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-base capitalize leading-5 text-gray-500">
                  {translate("table_header_date", language)}
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                  {formatDate(transaction.createdAt, language)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-base capitalize leading-5 text-gray-500">
                  {translate("table_header_status", language)}
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900">
                  {["success", "succeeded", "processed"].includes(
                    transaction.status
                  ) && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800 uppercase">
                      {translate(transaction.status, language)}
                    </span>
                  )}
                  {["pending", "scheduled", "processing"].includes(
                    transaction.status
                  ) && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-800 uppercase">
                      {translate(transaction.status, language)}
                    </span>
                  )}
                  {["payment_failed", "failed", "canceled"].includes(
                    transaction.status
                  ) && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-cool-gray-100 text-cool-gray-800 uppercase">
                      {translate(transaction.status, language)}
                    </span>
                  )}
                </dd>
              </div>
              {transaction.notes && (
                <div className="sm:col-span-2">
                  <dt className="text-base capitalize leading-5 text-gray-500">
                    {translate("label_notes", language)}
                  </dt>
                  <dd className="mt-1 text-sm leading-5 text-gray-900">
                    {transaction.notes}
                  </dd>
                </div>
              )}
            </dl>
          )}
        </div>
      </PageContent>
    </Layout>
  );
};

export default TransactionInfoPage;
