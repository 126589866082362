import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";

import {
  Business,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
} from "../../generated/graphql";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import { translate } from "../../helpers";

interface Props {
  business: Business;
  isFavorite: boolean;
}

const BusinessCard: React.FC<Props> = ({ business, isFavorite, ...attr }) => {
  const language = useLanguagePreference();

  const [
    aFavorite,
    { data: aData, loading: aLoading, error: aError },
  ] = useAddFavoriteMutation();
  const [
    rFavorite,
    { data: rData, loading: rLoading, error: rError },
  ] = useRemoveFavoriteMutation();

  return (
    <li className="bg-white" {...attr}>
      <div className="block relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6">
        <div className="flex items-center justify-between space-x-4">
          {/* <!-- Repo name and link --> */}
          <Link
            className="min-w-0 space-y-3 hover:underline"
            to={`/app/search/${business.id}`}
          >
            <div className="flex items-center space-x-2">
              {/* <span aria-label="Running" className="h-4 w-4 bg-green-100 rounded-full flex items-center justify-center">
                                <span className="h-2 w-2 bg-green-400 rounded-full"></span>
                            </span> */}
              {/* <svg
                className="flex-shrink-0 w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>*/}
              <span className="block">
                <h2 className="text-xl leading-5">
                  {business.friendlyName ? business.friendlyName : business.name}
                </h2>
              </span>
            </div>
            <div className="relative group flex items-center space-x-2.5 text-gray-600">
              <div className="text-sm leading-5 font-medium truncate">
                ID <span>&middot;</span> {business.handle}
              </div>
            </div>
          </Link>
          <Link to={`/app/search/${business.id}`} className="sm:hidden">
            <svg
              className="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </Link>
          {/* <!-- Repo meta info --> */}
          <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
            <p className="flex items-center space-x-4">
              {!isFavorite && (
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      const result = await aFavorite({
                        variables: {
                          id: business.id,
                        },
                      });

                      if (
                        result.data &&
                        result.data.addFavorite &&
                        result.data.addFavorite.success
                      ) {
                        // setIsFavorite(true);
                        return;
                      }

                      if (result.errors) {
                        // show errors.
                        console.warn(result.errors.toString());
                        return;
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                  className="inline-flex w-6 h-6"
                >
                  <svg
                    className="flex-shrink-0 w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    />
                  </svg>
                </button>
              )}
              {isFavorite && (
                <button
                  type="button"
                  className="inline-flex w-6 h-6"
                  onClick={async () => {
                    try {
                      const result = await rFavorite({
                        variables: {
                          id: business.id,
                        },
                      });

                      if (
                        result.data &&
                        result.data.removeFavorite &&
                        result.data.removeFavorite.success
                      ) {
                        // setIsFavorite(false);
                        return;
                      }

                      if (result.errors) {
                        // show errors.
                        console.warn(result.errors.toString());
                        return;
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  <svg
                    className="flex-shrink-0 w-7 h-7 text-yellow-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    />
                  </svg>
                </button>
              )}
            </p>
            <p className="flex text-gray-500 text-sm leading-5 space-x-2">
              {/* <span>Laravel</span> */}
              {/* <span>&middot;</span> */}
              <span className="uppercase">{translate("label_manager", language)}</span>
              <span>&middot;</span>
              {business.manager && business.manager.profile && (
                <span>
                  {business.manager.profile.firstName}{" "}
                  {business.manager.profile.lastName}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default BusinessCard;
