import React from "react";
import { RouteComponentProps } from "@reach/router";

import Root from "./root";
import IndexPage from "./pages/index";
import ProfilePage from "./pages/profile";
import PrivateRoute from "./components/privateRoute";
import PaymentMethodIndex from "./pages/payment-methods";
import PaymentMethodAdd from "./pages/payment-methods/add";
import PaymentMethodUpdate from "./pages/payment-methods/update";
import PaymentMethodVerify from "./pages/payment-methods/verify";
import SearchIndex from "./pages/search";
import SearchInfo from "./pages/search/info";
import SearchGive from "./pages/search/give";
import Preferences from "./pages/preferences";
import Feedback from "./pages/feedback";
import TransactionInfo from "./pages/transactions/info";

const PaymentMethod: React.FC<RouteComponentProps> = ({ children }) => (
  <>{children}</>
);
const Search: React.FC<RouteComponentProps> = ({ children }) => <>{children}</>;

const App: React.FC = () => {
  return (
    <Root>
      <PrivateRoute>
        {/* Home */}
        <IndexPage path="/" />
        {/* Payment Methods */}
        <PaymentMethod path="payment-methods">
          <PaymentMethodAdd path="add" />
          <PaymentMethodIndex path="/" />
          <PaymentMethodUpdate path="/:paymentMethod" />
          <PaymentMethodVerify path="/:paymentMethod/verify/:id" />
        </PaymentMethod>
        {/* Profile */}
        <ProfilePage path="profile" />
        {/* Search */}
        <Search path="search">
          <SearchIndex path="/" />
          <SearchInfo path="/:businessId" />
          <SearchGive path="/:businessId/give" />
        </Search>
        <Preferences path="preferences" />
        <Feedback path="feedback" />
        <TransactionInfo path="/transactions/:transactionId" />
      </PrivateRoute>
    </Root>
  );
};

export default App;
