import React, { useEffect, useState } from "react";
import { navigate } from "gatsby-plugin-intl";
import { Router, Redirect } from "@reach/router";

import { isLoggedIn, isBrowser } from "../services/auth";

const PrivateRoute = ({ children }) => {
  const authenticated = isLoggedIn();
  
  if (!authenticated && isBrowser() && window.location.pathname !== `/login`) {
    navigate("/login");
    return null;
  }

  return <Router basepath="/app">{children}</Router>;
};

export default PrivateRoute;
