import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Transition } from "@tailwindui/react";
import tw from "twin.macro";
import { navigate } from "gatsby";

import {
  useUpdateUserPaymentMethodMutation,
  PaymentMethod,
  GetPaymentMethodsDocument,
  GetPaymentMethodsQuery,
} from "../../generated/graphql";
import Toggle from "../../../inputs/toggle";
import Portal from "../portal";
import { translate } from "../../helpers";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import PrimaryButton from "../buttons/primary";

const ValidationSchema = Yup.object().shape({
  fullName: Yup.string().required("Required"),
  nickName: Yup.string().required("Required"),
  isDefault: Yup.boolean(),
});

interface UpdatePageProps {
  paymentMethod: PaymentMethod;
  isDefault: boolean;
}

const UpdateBankAccountForm: React.FC<UpdatePageProps> = ({
  paymentMethod,
  isDefault,
}) => {
  const language = useLanguagePreference();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [
    updatePM,
    { data: pmData, loading: pmLoading, error: pmError },
  ] = useUpdateUserPaymentMethodMutation();

  useEffect(() => {
    if (pmError) {
      setErrorMessage(pmError.message);
      setShowErrorMessage(true);
    }

    if (
      pmData &&
      pmData.updateUserPaymentMethod &&
      pmData.updateUserPaymentMethod.success
    ) {
      const pm = pmData.updateUserPaymentMethod.paymentMethod;
      navigate("/app/payment-methods", { state: { newId: pm.id } });
    }
  }, [pmData, pmLoading, pmError]);

  return (
    <>
      <div className="bg-white py-8 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
        <div className="sm:w-1/2 mx-auto">
          <Formik
            initialValues={{
              fullName: paymentMethod.fullName,
              nickName: paymentMethod.nickName,
              isDefault,
            }}
            validationSchema={ValidationSchema}
            onSubmit={async (values, actions) => {
              try {
                await updatePM({
                  variables: {
                    id: paymentMethod.id,
                    data: {
                      nickName: values.nickName,
                      fullName: values.fullName,
                      isDefault: values.isDefault,
                    },
                  },
                });

                actions.setSubmitting(false);

                // @TODO -- shouldn't get here. Log if does.
              } catch (error) {
                console.error(error);
                setErrorMessage(error.message);
                setShowErrorMessage(true);
                actions.setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, errors, values, setFieldValue }) => (
              <Form>
                {/* Full Name Field */}
                <div>
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate("placeholder_input_full_name", language)}
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="fullName"
                      className={`form-input block w-full sm:text-sm sm:leading-5 pr-10 ${
                        errors.fullName
                          ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage name="fullName">
                    {(msg: string): React.ReactNode => (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="fullName-error"
                      >
                        {msg}
                      </p>
                    )}
                  </ErrorMessage>
                </div>
                {/* Nickname Field */}
                <div className="mt-6">
                  <label
                    htmlFor="nickName"
                    className="block text-sm font-medium leading-5 text-gray-700"
                  >
                    {translate("placeholder_input_nickname", language)}
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <Field
                      type="text"
                      name="nickName"
                      className={`form-input block w-full sm:text-sm sm:leading-5 ${
                        errors.nickName
                          ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage name="nickName">
                    {(msg: string): React.ReactNode => (
                      <p
                        className="mt-2 text-sm text-red-600"
                        id="nickName-error"
                      >
                        {msg}
                      </p>
                    )}
                  </ErrorMessage>
                </div>
                <div className="sm:col-span-2 mt-6">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Toggle
                        isOn={values.isDefault}
                        onClick={() =>
                          setFieldValue("isDefault", !values.isDefault, true)
                        }
                        color={tw`bg-green-600`}
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-base leading-6 text-gray-700">
                        {translate("statement_set_as_default", language)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-6">
                  <PrimaryButton
                    title={translate("button_save", language)}
                    type="submit"
                    additionalButtonClasses="group relative w-full flex justify-center"
                    disabled={isSubmitting || pmLoading}
                    icon={
                      (isSubmitting || pmLoading) && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      )
                    }
                  />
                  {/* <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    disabled={isSubmitting || pmLoading}
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      
                    </span>
                    
                  </button> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Portal id="modalPortal">
        {/* Error Message Modal */}
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          css={[!showErrorMessage && tw`hidden`]}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* <!--
                        Background overlay, show/hide based on modal state.

                        Entering: "ease-out duration-300"
                        From: "opacity-0"
                        To: "opacity-100"
                        Leaving: "ease-in duration-200"
                        From: "opacity-100"
                        To: "opacity-0"
                    --> */}
            <Transition
              show={showErrorMessage}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>
            </Transition>
            {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
            <span className="hidden sm:align-middle sm:h-screen"></span>&#8203;
            {/* <!--
                            Modal panel, show/hide based on modal state.

                            Entering: "ease-out duration-300"
                            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            To: "opacity-100 translate-y-0 sm:scale-100"
                            Leaving: "ease-in duration-200"
                            From: "opacity-100 translate-y-0 sm:scale-100"
                            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        --> */}
            <Transition
              show={showErrorMessage}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                    aria-label="Close"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {translate("error_mutation_update_bank", language)}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm leading-5 text-gray-600">
                        {errorMessage}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {/* <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                                    <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                        Deactivate
                                    </button>
                                    </span> */}
                  <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button
                      type="button"
                      onClick={() => setShowErrorMessage(false)}
                      className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    >
                      {translate("overlay_button_close", language)}
                    </button>
                  </span>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </Portal>
    </>
  );
};

export default UpdateBankAccountForm;
