import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { DatePicker } from "@material-ui/pickers";
import tw from "twin.macro";
import Moment from "moment";
import { Link, RouteComponentProps } from "@reach/router";

import Layout from "../layouts/main";
import { states, translate, getDate } from "../helpers";
import {
  useMeQueryQuery,
  useUpdateUserMutation,
  User,
} from "../generated/graphql";
import UpdatedAlert from "../components/alerts/updated";
import Portal from "../components/portal";
import ErrorModal from "../components/modals/error";
import PageHeader from "../layouts/pageHeader";
import PageContent from "../layouts/pageContent";
import LoadingModal from "../components/modals/loading";
import GoBackButton from "../components/buttons/goBack";
import useLanguagePreference from "../hooks/useLanguagePreference";
import PrimaryButton from "../components/buttons/primary";

const ProfilePage: React.FC<RouteComponentProps> = () => {
  const language = useLanguagePreference();

  const ValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required(translate("warning_required", language)),
    // password: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(50, "Too Long!"),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref('password'), null], "Passwords don't match"),
    firstName: Yup.string().required(translate("warning_required", language)),
    lastName: Yup.string().required(translate("warning_required", language)),
    // phoneNumber: Yup.string()
    //   .required(translate("warning_required", language))
    //   .min(14, "Phone number is not valid"),
    address_1: Yup.string().required(translate("warning_required", language)),
    city: Yup.string().required(translate("warning_required", language)),
    state: Yup.string().required(translate("warning_required", language)),
    zipcode: Yup.string().required(translate("warning_required", language)),
  });

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const { data, loading, error } = useMeQueryQuery({
    ssr: false,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      console.log("COMPLETED:", data);
    },
  });

  const [
    updateUser,
    { data: uData, loading: uLoading, error: uError },
  ] = useUpdateUserMutation();

  const [selectedDate, handleDateChange] = useState(null);

  const [me, setMe] = useState<User>(null);

  useEffect(() => {
    if (uData && uData.updateUser && uData.updateUser.success) {
      // show success alert
      setShowAlert(true);
    }

    if (error) {
      setErrorMessage(error.message);
      setShowErrorMessage(true);
    }
  }, [uData, error]);

  useEffect(() => {
    if (data && data.me) {
      setMe(data.me);
      handleDateChange(getDate(data.me.profile.dob, language));
    }

    if (error) {
      setErrorMessage(error.message);
      setShowErrorMessage(true);
    }
  }, [data, error, language]);

  return (
    <Layout>
      <PageHeader title={translate("screen_title_profile", language)}>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <GoBackButton url={`/app`} />
        </div>
      </PageHeader>
      <PageContent>
        <div className="py-8 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
          <div className="sm:w-2/3 mx-auto">
            {me && (
              <Formik
                initialValues={{
                  email: me.username,
                  // password: "",
                  // confirmPassword: "",
                  firstName: me.profile.firstName,
                  lastName: me.profile.lastName,
                  // phoneNumber: me.profile,
                  address_1: me.profile.address.street1,
                  address_2: me.profile.address.street2
                    ? me.profile.address.street2
                    : "",
                  city: me.profile.address.city,
                  state: me.profile.address.state,
                  zipcode: me.profile.address.zipcode,
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                  try {
                    const data = {
                      username: values.email,
                      email: values.email,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      dob: selectedDate.toISOString(),
                      address: {
                        street1: values.address_1,
                        street2: values.address_2,
                        city: values.city,
                        state: values.state,
                        zipcode: values.zipcode,
                      },
                      // password: values.confirmPassword
                    };

                    await updateUser({
                      variables: {
                        data,
                      },
                    });

                    actions.setSubmitting(false);
                    // @TODO -- shouldn't get here. Log if does.
                  } catch (error) {
                    console.error(error);
                    setErrorMessage(error.message);
                    setShowErrorMessage(true);
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({ isSubmitting, errors, values, setFieldValue }) => (
                  <Form className="grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate("placeholder_input_email", language)}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <Field
                          type="email"
                          name="email"
                          className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 bg-gray-100 pointer-events-none ${
                            errors.email
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : ""
                          }`}
                          placeholder="you@example.com"
                          autoComplete={"email"}
                          disabled
                        />
                      </div>
                      <ErrorMessage name="email">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    {/* <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">Password</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <Field
                            type="password"
                            name="password"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.password
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                              }`}
                            autoComplete={'new-password'}
                          />
                        </div>
                        <ErrorMessage name="password">
                          {(msg: string): React.ReactNode => (
                            <p className="mt-2 text-sm text-red-600" id="password-error">
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium leading-5 text-gray-700">Confirm Password</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <Field
                            type="password"
                            name="confirmPassword"
                            className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                              errors.confirmPassword
                                ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : ""
                              }`}
                            autoComplete={'new-password'}
                          />
                        </div>
                        <ErrorMessage name="confirmPassword">
                          {(msg: string): React.ReactNode => (
                            <p className="mt-2 text-sm text-red-600" id="confirmPassword-error">
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div> */}
                    <div>
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate("placeholder_input_first_name", language)}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        {/* <input id="firstName" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                        <Field
                          type="text"
                          name="firstName"
                          className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                            errors.firstName
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : ""
                          }`}
                          placeholder=""
                        />
                      </div>
                      <ErrorMessage name="firstName">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="firstName-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate("placeholder_input_last_name", language)}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        {/* <input id="last_name" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                        <Field
                          type="text"
                          name="lastName"
                          className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                            errors.lastName
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : ""
                          }`}
                          placeholder=""
                        />
                      </div>
                      <ErrorMessage name="lastName">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="lastName-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label
                        htmlFor="date_of_birth"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate("placeholder_input_date_of_birth", language)}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <DatePicker
                          format={"MMMM DD, yyyy"}
                          value={selectedDate}
                          onChange={handleDateChange}
                          open={isOpen}
                          onOpen={() => setIsOpen(true)}
                          onClose={() => setIsOpen(false)}
                          TextFieldComponent={props => {
                            return (
                              <button
                                onClick={() => setIsOpen(true)}
                                type="button"
                                id="date_of_birth"
                                className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 text-left"
                              >
                                {props.value}
                              </button>
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* <div>
                        <label htmlFor="phoneNumber" className="block text-sm font-medium leading-5 text-gray-700">Phone Number</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <Field name="phoneNumber">
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              let formattedValue = formatPhoneNumber(field.value);

                              if (formattedValue) {
                                field.value = formattedValue;
                              }

                              return (
                                <>
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 sm:text-sm sm:leading-5">
                                      + 1
                                          </span>
                                  </div>
                                  <input
                                    type="text"
                                    className={
                                      `form-input py-3 px-4 block w-full transition ease-in-out duration-150 pl-10 ${
                                      errors.phoneNumber
                                        ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                        : ""
                                      }`
                                    }
                                    {...field}
                                  />
                                </>
                              )
                            }}
                          </Field>
                        </div>
                        <ErrorMessage name="phoneNumber">
                          {(msg: string): React.ReactNode => (
                            <p className="mt-2 text-sm text-red-600" id="phoneNumber-error">
                              {msg}
                            </p>
                          )}
                        </ErrorMessage>
                      </div> */}
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="address_1"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate(
                          "placeholder_input_address_street_1",
                          language
                        )}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        {/* <input id="address_1" type="text" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                        <Field
                          type="text"
                          name="address_1"
                          className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                            errors.address_1
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : ""
                          }`}
                          placeholder=""
                        />
                      </div>
                      <ErrorMessage name="address_1">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="address_1-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label
                        htmlFor="address_2"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate(
                          "placeholder_input_address_street_2",
                          language
                        )}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        {/* <input id="address_2" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                        <Field
                          type="text"
                          name="address_2"
                          className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150`}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate("placeholder_input_address_city", language)}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        {/* <input id="city" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                        <Field
                          type="text"
                          name="city"
                          className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                            errors.city
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : ""
                          }`}
                          placeholder=""
                        />
                      </div>
                      <ErrorMessage name="city">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="city-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate("placeholder_input_address_state", language)}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        {/* <select id="state" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150 form-select">
                                  
                                </select> */}
                        <Select
                          defaultValue={
                            states[
                              states.findIndex(
                                element => element.value === values.state
                              )
                            ]
                          }
                          options={states}
                          onChange={option =>
                            setFieldValue("state", option.value, true)
                          }
                        />
                      </div>
                      <ErrorMessage name="state">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="state-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div>
                      <label
                        htmlFor="zipcode"
                        className="block text-sm font-medium leading-5 text-gray-700"
                      >
                        {translate(
                          "placeholder_input_address_zipcode",
                          language
                        )}
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        {/* <input id="zipcode" className="form-input py-3 px-4 block w-full transition ease-in-out duration-150" /> */}
                        <Field
                          type="text"
                          name="zipcode"
                          className={`form-input py-3 px-4 block w-full transition ease-in-out duration-150 ${
                            errors.zipcode
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : ""
                          }`}
                          placeholder=""
                        />
                      </div>
                      <ErrorMessage name="zipcode">
                        {(msg: string): React.ReactNode => (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="zipcode-error"
                          >
                            {msg}
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="sm:col-span-2">
                      <PrimaryButton
                        title={translate("button_update", language)}
                        type="submit"
                        disabled={isSubmitting || uLoading}
                        additionalButtonClasses="w-full inline-flex items-center justify-center"
                        additionalContainerClasses="w-full inline-flex"
                        icon={
                          (isSubmitting || uLoading) && (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          )
                        }
                      />
                      {/* <span className="w-full inline-flex rounded-md shadow-sm">
                        <button
                          
                          className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                          
                        >
                          
                          
                        </button>
                      </span> */}
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </PageContent>
      <Portal id="notificationPortal">
        <UpdatedAlert
          show={showAlert}
          message={translate("statement_updated_success", language)}
          onClose={() => setShowAlert(false)}
          type="success"
        />
      </Portal>
      <Portal id="modalPortal">
        <LoadingModal show={loading} />
        <ErrorModal
          msg={errorMessage}
          show={showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
        />
      </Portal>
    </Layout>
  );
};

export default ProfilePage;
