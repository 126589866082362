import React, { useEffect, useState, useCallback } from "react";
import tw from "twin.macro";
import {css} from "@emotion/core";

import Sidebar from "./sidebar";
import BtnGiveBgSvg from "../../../svgs/btn-give-bg.inline.svg";
import IconPaperDollarSvg from "../../../svgs/icon-paper-dollar.inline.svg";
import { useMeQueryQuery } from "../generated/graphql";
import useLanguagePreference from "../hooks/useLanguagePreference";
import Banner from "../components/banner";
import { isPendingBankAccount, translate } from "../helpers";

interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  showGiveButton?: boolean;
}

const styles = css`
.css-yk16xz-control, .css-1pahdxg-control {
  min-height: 50px;
}
`;

const MainLayout: React.FC<Props> = ({ children, showGiveButton = false }) => {
  const [open, setOpen] = useState<boolean>(false);

  const { data, error, loading } = useMeQueryQuery();
  const language = useLanguagePreference();
  const [showMessage, setShowMessage] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.me && data.me.paymentMethods.edges) {
      // user with payment methods
      data.me.paymentMethods.edges.forEach(({ node }) => {
        if (isPendingBankAccount(node)) {
          setShowMessage(true);
        }
      });
    }
  }, [data]);

  return (
    <>
      <div 
        css={styles}
        className="h-screen flex overflow-hidden bg-cool-gray-100"
      >
        <Sidebar
          open={open}
          onCloseEvent={() => setOpen(false)}
          username={
            data && data.me && data.me.profile
              ? `${data.me.profile.firstName} ${data.me.profile.lastName}`
              : ""
          }
          email={data && data.me ? data.me.username : ""}
          language={language}
        />
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <Banner 
            message={translate("unverified_pm_message", language)}
            show={showMessage}
            // actionButton={<>
            //   <a href="#" className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50">
            //     Verify
            //   </a>
            // </>}
            onClose={() => setShowMessage(false)}
          />
          <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-700 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
              aria-label="Open sidebar"
              onClick={() => setOpen(!open)}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div id="searchPortal"></div>
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {children}
          </main>
          {showGiveButton && (
            <div className="absolute inset-x-0 bottom-0 h-20 bg-gray-100 md:hidden">
              <div css={[tw`pt-3 relative px-3`]}>
                <span className="absolute left-0 top-0 w-full">
                  <BtnGiveBgSvg
                    css={[tw`mx-auto h-16`]}
                    width={"auto"}
                    height={"auto"}
                  />
                </span>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-green-500 font-bold text-lg bg-white focus:outline-none focus:border-green-700 focus:shadow-outline-gree active:bg-green-700 transition duration-150 ease-in-out shadow-lg z-10"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <IconPaperDollarSvg width={"35px"} height={"auto"} />
                  </span>
                  GIVE
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div id="modalPortal"></div>
      <div id="notificationPortal"></div>
    </>
  );
};

export default MainLayout;
