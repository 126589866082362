import React, { useEffect, useState, useCallback } from "react";
import tw, { TwStyle } from "twin.macro";
import { css } from "@emotion/core";
import moment from "moment";
import { RouteComponentProps, Link } from "@reach/router";

import Layout from "../layouts/main";
import {
  // useMeQueryLazyQuery,
  useMeQueryQuery,
  useGetMyTransactionsQuery,
  Transaction,
  User,
} from "../generated/graphql";
import SvgImage from "../../../svgs/image-empty-transactions.inline.svg";
import Line from "../../../svgs/bg-dotted-line.inline.svg";
import BtnGiveBgSvg from "../../../svgs/btn-give-bg.inline.svg";
import IconPaperDollarSvg from "../../../svgs/icon-paper-dollar.inline.svg";
import PageHeader from "../layouts/pageHeader";
import PageContent from "../layouts/pageContent";
import { translate, formatAmount, formatDate } from "../helpers";
import useLanguagePreference from "../hooks/useLanguagePreference";
import TransactionCard from "../components/cards/transaction";
import PrimaryButton from "../components/buttons/primary";
import SecondaryButton from "../components/buttons/secondary";
import LoadingModal from "../components/modals/loading";

const IndexPage: React.FC<RouteComponentProps> = () => {
  const language = useLanguagePreference();
  // const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [startCursor, setStartCursor] = useState<string>("");
  const [endCursor, setEndCursor] = useState<string>("");

  const [hasBefore, setHasBefore] = useState<boolean>(false);
  const [hasAfter, setHasAfter] = useState<boolean>(false);

  const [userName, setUserName] = useState<string>();

  const {
    data,
    loading,
    error,
    refetch,
    networkStatus,
    fetchMore,
  } = useGetMyTransactionsQuery({
    // pollInterval: 100,
    // partialRefetch: true,
    // ssr: false,
    fetchPolicy: "cache-and-network",
    // notifyOnNetworkStatusChange: true,
    // variables: {
    //   first: 10,
    // },
    onCompleted: data => {
      console.log("COMPLETED:", data);
    },
  });

  useEffect(() => {
    if (data && data.me) {
      const { me } = data;

      if (me.profile) {
        setUserName(`${me.profile.firstName} ${me.profile.lastName}`);
      }

      if (me.transactions) {
        const { transactions } = me;
        console.log("TRANSACTIONS:", transactions);
        const { pageInfo, edges } = transactions;
        console.log("DATA PAGEINFO:", pageInfo);
        setHasPreviousPage(pageInfo.hasPreviousPage);
        setHasNextPage(pageInfo.hasNextPage);
        setStartCursor(pageInfo.startCursor);
        setEndCursor(pageInfo.endCursor);
        // setTransactions(edges.map(edge => edge.node as Transaction));
      }
    }
  }, [data]);

  const nextPage = useCallback(async () => {
    const more = await fetchMore({
      variables: {
        first: 10,
        after: endCursor,
      },
    });

    if (more && more.data && more.data.me) {
      const me = more.data.me;
      if (me.transactions) {
        const { transactions } = me;
        const { pageInfo, edges } = transactions;
        console.log("FETCH MORE:", pageInfo);
        if (edges.length) {
          setHasBefore(true);
        }
      }
    }
  }, [endCursor]);

  const previouPage = useCallback(async () => {
    const more = await refetch({
      before: endCursor,
      last: 10,
    });

    if (more && more.data && more.data.me) {
      const { transactions } = more.data.me;
      const { pageInfo, edges } = transactions;

      if (edges.length) {
        setHasAfter(true);
      }
    }
  }, [endCursor]);

  return (
    <Layout>
      {/* <!-- Page header --> */}
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-cool-gray-200">
            <div className="flex-1 min-w-0">
              {/* <!-- Profile --> */}
              <div className="flex items-center">
                {/* <img className="hidden h-15 w-15 rounded-full sm:block" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80" alt="" /> */}
                <div>
                  <div className="flex items-center">
                    {/* <img className="h-15 w-15 rounded-full sm:hidden" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80" alt="" /> */}
                    <h1 className="text-2xl font-bold leading-7 text-cool-gray-900 sm:leading-9 sm:truncate">
                      <span className="capitalize">
                        {moment().hour() < 12 &&
                          translate("good_morning", language)}
                        {moment().hour() >= 12 &&
                          moment().hour() < 17 &&
                          translate("good_afternoon", language)}
                        {moment().hour() >= 17 &&
                          translate("good_evening", language)}
                      </span>
                      , <span className="capitalize">{userName}</span>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <SecondaryButton
                title={translate("screen_title_add_payment_method", language)}
                url={"/app/payment-methods/add"}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="-ml-1 mr-2 h-5 w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                }
              />
              <PrimaryButton
                title={translate("send_money", language)}
                url={"/app/search"}
                icon={
                  <svg
                    className="-ml-1 mr-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </div>
      {data && data.me && (
        <div className="mb-8">
          <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-cool-gray-900 sm:px-6 lg:px-8">
            {translate("screen_title_transactions", language)}
          </h2>

          {/* <!-- Activity list (smallest breakopoint only) --> */}
          <div className="shadow sm:hidden">
            <ul className="mt-2 divide-y divide-cool-gray-200 overflow-hidden shadow sm:hidden">
              {data.me.transactions.edges.map((edge, i) => {
                const transaction = edge.node as Transaction;
                return (
                  <li key={i}>
                    <Link
                      to={`/app/transactions/${transaction.id}`}
                      className="block px-4 py-4 bg-white hover:bg-cool-gray-50"
                    >
                      <div className="flex items-center space-x-4">
                        <div className="flex-1 flex space-x-2 truncate">
                          {/* <!-- Heroicon name: cash --> */}
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-cool-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <div className="text-cool-gray-500 text-sm truncate">
                            <p className="truncate">
                              <span className="capitalize">
                                {translate("payment", language)}
                              </span>{" "}
                              {translate("pagination_2", language)} @
                              {transaction.destination.handle}
                            </p>
                            <p>
                              <span className="text-cool-gray-900 font-medium">
                                {formatAmount(transaction.amount)}
                              </span>{" "}
                              USD
                            </p>
                            <p>{formatDate(transaction.date, language)}</p>
                          </div>
                        </div>
                        <div>
                          {/* <!-- Heroicon name: chevron-right --> */}
                          <svg
                            className="flex-shrink-0 h-5 w-5 text-cool-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </Link>
                  </li>
                );
              })}

              {/* <!-- More items... --> */}
            </ul>
            {/* <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200">
              <div className="flex-1 flex justify-between">
                <a href="#" className="relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                  {translate("button_previous", language)}
                </a>
                <a href="#" className="ml-3 relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                  {translate("button_next", language)}
                </a>
              </div>
            </nav> */}
          </div>

          {/*<!-- Activity table (small breakopoint and up) -->*/}
          <div className="hidden sm:block">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-cool-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          {translate("table_header_transaction", language)}
                        </th>
                        <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          {translate("table_header_amount", language)}
                        </th>
                        <th className="hidden px-6 py-3 bg-cool-gray-50 text-left text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider md:block">
                          {translate("table_header_status", language)}
                        </th>
                        <th className="px-6 py-3 bg-cool-gray-50 text-right text-xs leading-4 font-medium text-cool-gray-500 uppercase tracking-wider">
                          {translate("table_header_date", language)}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-cool-gray-200">
                      {data.me.transactions.edges.map((edge, i) => {
                        const transaction = edge.node as Transaction;
                        return (
                          <tr key={i} className="bg-white">
                            <td className="max-w-0 w-full px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-900">
                              <div className="flex">
                                <Link
                                  to={`/app/transactions/${transaction.id}`}
                                  className="group inline-flex space-x-2 truncate text-sm leading-5"
                                >
                                  {/* <!-- Heroicon name: cash --> */}
                                  <svg
                                    className="flex-shrink-0 h-5 w-5 text-cool-gray-400 group-hover:text-cool-gray-500 transition ease-in-out duration-150"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <p className="text-cool-gray-500 truncate group-hover:text-cool-gray-900 transition ease-in-out duration-150">
                                    <span className="capitalize">
                                      {translate("payment", language)}
                                    </span>{" "}
                                    {translate("pagination_2", language)} @
                                    {transaction.destination.handle}
                                  </p>
                                </Link>
                              </div>
                            </td>
                            <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                              <span className="text-cool-gray-900 font-medium">
                                {formatAmount(transaction.amount)}{" "}
                              </span>
                              USD
                            </td>
                            <td className="hidden px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500 md:block">
                              {["success", "succeeded", "processed"].includes(
                                transaction.status
                              ) && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800 uppercase">
                                  {translate(transaction.status, language)}
                                </span>
                              )}
                              {["pending", "scheduled", "processing"].includes(
                                transaction.status
                              ) && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-800 uppercase">
                                  {translate(transaction.status, language)}
                                </span>
                              )}
                              {[
                                "payment_failed",
                                "failed",
                                "canceled",
                              ].includes(transaction.status) && (
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-cool-gray-100 text-cool-gray-800 uppercase">
                                  {translate(transaction.status, language)}
                                </span>
                              )}
                            </td>
                            <td className="px-6 py-4 text-right whitespace-no-wrap text-sm leading-5 text-cool-gray-500">
                              {formatDate(transaction.date, language)}
                            </td>
                          </tr>
                        );
                      })}

                      {/* <!-- More rows... --> */}
                    </tbody>
                  </table>
                  {/* <!-- Pagination --> */}
                  {/* <nav className="bg-white px-4 py-3 flex items-center justify-between border-t border-cool-gray-200 sm:px-6">
                    <div className="hidden sm:block">
                      <p className="text-sm leading-5 text-cool-gray-700">
                        <span className="capitalize">{translate("pagination_1", language)}</span>
                        {` `}
                        <span className="font-medium">1</span>
                        {` `}
                        {translate("pagination_2", language)}
                        {` `}
                        <span className="font-medium">10</span>
                        {` `}
                        {translate("pagination_3", language)}
                        {` `}
                        <span className="font-medium">20</span>
                        {` `}
                        <span className="capitalize">{translate("pagination_4", language)}</span>
                      </p>
                    </div>
                    <div className="flex-1 flex justify-between sm:justify-end">
                      <a href="#" className="relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                        {translate("button_previous", language)}
                      </a>
                      <a href="#" className="ml-3 relative inline-flex items-center px-4 py-2 border border-cool-gray-300 text-sm leading-5 font-medium rounded-md text-cool-gray-700 bg-white hover:text-cool-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-cool-gray-100 active:text-cool-gray-700 transition ease-in-out duration-150">
                        {translate("button_next", language)}
                      </a>
                    </div>
                  </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data && data.me && data.me.transactions.edges.length === 0 && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pt-4 pb-4">
          <div style={{ width: "85%" }} css={[tw`mx-auto my-8`]}>
            <SvgImage
              css={tw`max-w-xl mx-auto`}
              width={"auto"}
              height={"auto"}
            />
            <div css={[tw`text-center my-6`]}>
              <p css={[tw`text-gray-700 font-bold text-lg`]}>
                {translate("empty_transactions_title", language)}
              </p>
              <p css={[tw`text-blue-200 font-semibold text-base text-lg`]}>
                {translate("empty_transactions_sub_title_1", language)}
              </p>
              <p css={[tw`text-blue-200 font-semibold text-base text-lg`]}>
                {translate("empty_transactions_sub_title_2", language)}{" "}
                <span css={[tw`text-blue-300 font-bold`]}>
                  {translate("give", language)}
                </span>{" "}
                {translate("empty_transactions_sub_title_3", language)}
              </p>
            </div>
            <div style={{ width: "3px" }} css={[tw`mx-auto`]}>
              <Line width={"auto"} height={"auto"} />
            </div>
          </div>
        </div>
      )}
      <LoadingModal show={loading} />
    </Layout>
  );
};

export default IndexPage;
