import React from "react";

import App from "../components/app";

const AppPage:React.FC = () => {

  if (typeof window !== "undefined") {
    return <App />;
  }

  return null;
};

export default AppPage;
