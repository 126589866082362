import React from "react";
import { RouteComponentProps } from "@reach/router";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-dom";

import Layout from "../../layouts/main";
import CustomSearchBox from "../../components/customSearchBox";
import Portal from "../../components/portal";

const SearchIndexPage: React.FC<RouteComponentProps> = () => {
  const searchClient = algoliasearch(
    process.env.ALGOLIA_APP_ID,
    process.env.ALGOLIA_API_KEY
  );

  return (
    <Layout>
      <Portal id="searchPortal">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
          {/* <!-- Search bar --> */}
          <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
            <div className="flex-1 flex">
              <InstantSearch
                indexName={process.env.ALGOLIA_INDEX_NAME}
                searchClient={searchClient}
              >
                <CustomSearchBox />
                {/* <Hits /> */}
              </InstantSearch>
            </div>
          </div>
        </div>
      </Portal>
      <div
        id="searchResultPortal"
        className="max-w-6xl mx-auto md:px-4 lg:px-8"
      ></div>
    </Layout>
  );
};

export default SearchIndexPage;
