import React, { ReactChild } from "react";
import { Transition } from "@tailwindui/react";
import tw from "twin.macro";
import { translate } from "../../helpers";
import useLanguagePreference from "../../hooks/useLanguagePreference";

interface Props extends React.HTMLAttributes<HTMLElement> {
  show: boolean;
  globalIcon?: ReactChild;
  footer?: ReactChild;
}

const LoadingModal: React.FC<Props> = ({
  show,
  globalIcon,
  footer,
  children,
}) => {
  const lang = useLanguagePreference();
  return (
    <>
      {/* Loading Message Modal */}
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        css={[!show && tw`hidden`]}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          {/* <!--
                        Background overlay, show/hide based on modal state.

                        Entering: "ease-out duration-300"
                        From: "opacity-0"
                        To: "opacity-100"
                        Leaving: "ease-in duration-200"
                        From: "opacity-100"
                        To: "opacity-0"
                    --> */}
          <Transition
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
          </Transition>
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:align-middle sm:h-screen"></span>&#8203;
          {/* <!--
                            Modal panel, show/hide based on modal state.

                            Entering: "ease-out duration-300"
                            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            To: "opacity-100 translate-y-0 sm:scale-100"
                            Leaving: "ease-in duration-200"
                            From: "opacity-100 translate-y-0 sm:scale-100"
                            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        --> */}
          <Transition
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-64 md:w-full sm:p-6 max-w-lg"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="sm:flex sm:items-center sm:flex-col">
                {globalIcon && <>{globalIcon}</>}
                <div className="mt-3 text-center">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    {translate("overlay_loading", lang)}
                  </h3>
                </div>
              </div>
              {children && <>{children}</>}
              {footer && <>{footer}</>}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default LoadingModal;
