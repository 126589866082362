import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams, Link, navigate } from "@reach/router";

import Layout from "../../layouts/main";
import {
  useMyBusinessQueryQuery,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
  useMyFavoritesQuery,
} from "../../generated/graphql";
import PageHeader from "../../layouts/pageHeader";
import PageContent from "../../layouts/pageContent";
import { translate } from "../../helpers";
import useLanguagePreference from "../../hooks/useLanguagePreference";
import LoadingModal from "../../components/modals/loading";
import Portal from "../../components/portal";
import GoBackButton from "../../components/buttons/goBack";
import PrimaryButton from "../../components/buttons/primary";

const InfoSearchPage: React.FC<RouteComponentProps> = () => {
  const language = useLanguagePreference();
  const params = useParams();

  const { data, loading, error } = useMyBusinessQueryQuery({
    variables: {
      id: params.businessId,
    },
  });

  const {
    data: fData,
    loading: fLoading,
    error: fError,
  } = useMyFavoritesQuery();
  const [
    aFavorite,
    { data: aData, loading: aLoading, error: aError },
  ] = useAddFavoriteMutation();
  const [
    rFavorite,
    { data: rData, loading: rLoading, error: rError },
  ] = useRemoveFavoriteMutation();
  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  useEffect(() => {
    if (fData && fData.me && fData.me.favorites.edges.length > 0) {
      const isFavorite = fData.me.favorites.edges
        .map(edge => edge.node.id)
        .includes(params.businessId);
      setIsFavorite(isFavorite);
    }
  }, [fData]);

  return (
    <Layout>
      <PageHeader title={translate("screen_title_organization_info", language)}>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          <GoBackButton url={`/app/search`} />
          {data && data.business && (
            <PrimaryButton
              title={translate("send_money", language)}
              url={`/app/search/${params.businessId}/give`}
              icon={
                <svg
                  className="-ml-1 mr-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              }
              additionalContainerClasses="ml-3"
            />
            // <span className="ml-3 shadow-sm rounded-md">
            //   <button
            //     type="button"
            //     onClick={() => {
            //       navigate();
            //     }}
            //     className="inline-flex items-center px-6 py-2 border border-green-300 text-sm leading-5 font-medium rounded-md text-white bg-green-500 hover:text-white focus:outline-none focus:shadow-outline-green focus:border-green-300 active:text-white active:bg-green-50 transition duration-150 ease-in-out"
            //   >

            //   </button>
            // </span>
          )}
        </div>
      </PageHeader>
      <PageContent>
        <div className="max-w-4xl mx-auto">
          {error && <span>{error.message}</span>}
          {data && data.business && (
            <>
              <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900 relative">
                  <span className="inline-flex items-center">
                    {/*<svg
                      className="flex-shrink-0 w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>*/}
                    <span className="text-2xl">
                      {data.business.friendlyName ? data.business.friendlyName : data.business.name}
                    </span>
                  </span>
                  {!isFavorite && (
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          const result = await aFavorite({
                            variables: {
                              id: data.business.id,
                            },
                          });

                          if (
                            result.data &&
                            result.data.addFavorite &&
                            result.data.addFavorite.success
                          ) {
                            setIsFavorite(true);
                            return;
                          }

                          if (result.errors) {
                            // show errors.
                            console.warn(result.errors.toString());
                            return;
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                      className="inline-flex w-6 h-6 absolute right-0"
                    >
                      <svg
                        className="flex-shrink-0 w-7 h-7"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                      </svg>
                    </button>
                  )}
                  {isFavorite && (
                    <button
                      type="button"
                      className="inline-flex w-6 h-6 absolute right-0"
                      onClick={async () => {
                        try {
                          const result = await rFavorite({
                            variables: {
                              id: data.business.id,
                            },
                          });

                          if (
                            result.data &&
                            result.data.removeFavorite &&
                            result.data.removeFavorite.success
                          ) {
                            setIsFavorite(false);
                            return;
                          }

                          if (result.errors) {
                            // show errors.
                            console.warn(result.errors.toString());
                            return;
                          }
                        } catch (error) {
                          console.error(error);
                        }
                      }}
                    >
                      <svg
                        className="flex-shrink-0 w-7 h-7 text-yellow-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                      </svg>
                    </button>
                  )}
                </h3>
                <p className="mt-1 max-w-2xl text-base leading-5 text-gray-500">
                  ID <span>&middot;</span> {data.business.handle}
                </p>
              </div>
              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-base capitalize leading-5 text-gray-500">
                      {translate("label_manager", language)}
                    </dt>
                    <dd className="mt-1 text-sm capitalize leading-5 text-gray-900">
                      {data.business.manager &&
                        data.business.manager.profile && (
                          <span>{`${data.business.manager.profile.firstName} ${data.business.manager.profile.lastName}`}</span>
                        )}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-base capitalize leading-5 text-gray-500">
                      {translate("placeholder_input_email", language)}
                    </dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900">
                      {data.business.manager &&
                        data.business.manager.profile && (
                          <span>{`${data.business.manager.profile.email}`}</span>
                        )}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-base capitalize leading-5 text-gray-500">
                      {translate("label_phone_number", language)}
                    </dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900">
                      {data.business.address && (
                        <span>{data.business.address.phone}</span>
                      )}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-base capitalize leading-5 text-gray-500">
                      {translate("label_website", language)}
                    </dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900">
                      {data.business.website && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={data.business.website}
                        >
                          {data.business.website}
                        </a>
                      )}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-base capitalize leading-5 text-gray-500">
                      {translate("label_address", language)}
                    </dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900">
                      {data.business.address && (
                        <>
                          <span>{data.business.address.street1}</span>
                          <br />
                          {data.business.address.street2 && (
                            <>
                              <span>{data.business.address.street2}</span>
                              <br />
                            </>
                          )}
                          <span>
                            {data.business.address.city},{" "}
                            {data.business.address.state}
                          </span>
                          <br />
                          <span>{data.business.address.zipcode}</span>
                        </>
                      )}
                    </dd>
                  </div>
                  {data.business.description && <div className="sm:col-span-2">
                    <dt className="text-base capitalize leading-5 text-gray-500">
                      {translate("label_about", language)}
                    </dt>
                    <dd className="mt-1 text-sm leading-5 text-gray-900">
                      {data.business.description}
                    </dd>
                  </div>}
                </dl>
              </div>
            </>
          )}
        </div>
      </PageContent>
      <Portal id="modalPortal">
        <LoadingModal show={loading} />
      </Portal>
    </Layout>
  );
};

export default InfoSearchPage;
